import React from "react";
import {useTranslation} from "react-i18next";

import {useWindowSize} from "hooks";
import classNames from "classnames";

import Title from "components/atoms/TitleTemplate";
import {ReactPlayer} from "components/atoms";

import "./style.scss"

const HomeCurrentGameMode = () => {
    const {width} = useWindowSize()
    const {t} = useTranslation()

    return (
        <section className="home_current-game-mode">
            <Title underline={true}>{t("gameModesTitlePartOne")}<span> {t("gameModesTitlePartTwo")}</span></Title>
            <div className="home_current-game-mode_content container">
                <div className="home_current-game-mode_content_one">
                    <div className="second-block img_left">
                        <h2>{t("blitzRoyaleTitle")}</h2>
                        <Video textGameplay="Early Access" position={width > 991 ? "left" : "right"}>
                            {width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/1022732509?h=dd2d11d892&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="Website-BR"/>
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/1022732509?h=dd2d11d892&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="VSL_BR_4K.mp4"
                                />}
                        </Video>
                    </div>
                    <div className="first-block-content">
                        <h2>{t("blitzRoyaleTitle")}</h2>
                        <p>{t("blitzRoyaleDescription")}</p>
                    </div>
                </div>
                <div className="home_current-game-mode_content_two">
                    <div className="first-block-content">
                        <h2>{t("kartRaceTitle")}</h2>
                        <p>{t("kartRaceDescription")}</p>
                    </div>
                    <div className="second-block img_right">
                        <h2>{t("kartRaceTitle")}</h2>
                        <Video textGameplay="Early Access" position="right">
                            {width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/1022732069?h=9a49efc268&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="Website-kartrace"
                                />
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/1022732069?h=9a49efc268&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="Website-kartrace"
                                />}
                        </Video>
                    </div>
                </div>
                <div className="home_current-game-mode_content_three">
                    <div className="second-block img_left">
                        <h2>{t("socialDeductionTitle")}</h2>
                        <Video textGameplay="Early Access" position={width > 991 ? "left" : "right"}>
                            {width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/1022731517?h=8da43713e8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="Website-SocialDeduction"
                                />
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/1022731517?h=8da43713e8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="Website-SocialDeduction"
                                />}
                        </Video>
                    </div>
                    <div className="first-block-content">
                        <h2>{t("socialDeductionTitle")}</h2>
                        <p>{t("socialDeductionDescription")}</p>
                    </div>
                </div>
                <div className="home_current-game-mode_content_four">
                    <div className="first-block-content">
                        <h2>{t("basketballTitle")}</h2>
                        <p>{t("basketballDescription")}</p>
                    </div>
                    <div className="second-block img_right">
                        <h2>{t("basketballTitle")}</h2>
                        <Video textGameplay="In Development" position="right">
                            {width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/1023013603?h=fe0fc0534d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="Website_Basketball"
                                />
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/1023013603?h=fe0fc0534d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="Website_Basketball"
                                />}
                        </Video>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HomeCurrentGameMode

export const Video = ({position, textGameplay, children}) => {
    const gamePlayerContainerStyle = classNames("gameplay-container", position)
    const gamePlayerBlockStyle = classNames("gameplay",position)
    const videBlock = classNames("video-block_video", position)
    const vectorStyle = classNames("gameplay_vector", position)

    return (
        <div className="video-block">
            <div className={videBlock}>{children}</div>
            <div className={gamePlayerContainerStyle}>
                <div className={gamePlayerBlockStyle}>{textGameplay}</div>
            </div>
            <div className={vectorStyle}/>
        </div>
    )
}