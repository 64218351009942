import {useSelector} from "react-redux";
import classNames from "classnames";
import React, {memo, useEffect} from "react";

import {CHAINS_ENVIRONMENT, CHAINS_MAP, formatNumberToEuropeanStyle} from "utils";
import {useAuthorization, useBalance, useConnectedWallet} from "hooks";

import {Image} from "components/atoms";

import {png} from "assets/png";

const InfoBlock = () => {
    const {getKMTBalanceAndSave, getBalanceAndSave} = useBalance()
    const {isAuth} = useAuthorization()
    const {connectedChainId} = useConnectedWallet()

    const {userData, loading} = useSelector(state => state.authV2.signIn)

    useEffect(() => {
        getBalances()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    const noConnectedWallet = classNames("navigation_auth_user-details_panel_info_balance_voolah")
    const nicknameStyle = classNames("navigation_auth_user-details_panel_info_user-nickname", {"skeleton": loading})
    const noRegisteredWallet = classNames("navigation_auth_user-details_panel_info_balance_kompete")
    const formattedVoolahBalance = userData?.voolahBalance ? formatNumberToEuropeanStyle(userData?.voolahBalance) : 0
    const formattedKompeteBalance = userData?.kompeteBalance ? formatNumberToEuropeanStyle(Number(userData?.kompeteBalance).toFixed(2)) : 0

    const getBalances = async () => {
        if (isAuth) {
            const chainId = connectedChainId ?? CHAINS_MAP[CHAINS_ENVIRONMENT].BASE
            const {wallet} = userData

            await getKMTBalanceAndSave(chainId, wallet)
            await getBalanceAndSave(chainId, wallet)
        }
    }

    return (
        <div className="navigation_auth_user-details_panel_info">
            <span className={nicknameStyle}>{userData.nickname}</span>
            <div className="navigation_auth_user-details_panel_info_balance">
                <div className={noConnectedWallet}>
                    <Image alt="voolah" src={png.VoolahCoin}/>
                    <span>{formattedVoolahBalance}</span>
                </div>
                <div className={noRegisteredWallet}>
                    <Image alt="kompete-token" src={png.T1Png}/>
                    <span>{formattedKompeteBalance}</span>
                </div>
            </div>
        </div>
    )
}

export default memo(InfoBlock)