import {createXsollaFetch} from "config/createXsollaFetch";
import {GLOBAL, PROFILE, XSOLLA_STORE} from "../endpoint";
import {createFetch} from "config/createFetch";
import {ENVIRONMENTS, SERVER} from "utils";

// @TODO The API is not working , need to check
// const getEthPrice = async () => {
//     return fetch("https://api.coinbase.com/v2/prices/eth-usd/spot")
//         .then(result => result.json())
//         .then(result => result.data.amount)
//         .catch(() => {
//             throw new Error()
//         })
// }

const getCurrentIpData = () => {
    return fetch("https://ipapi.co/json/")
        .then(result => result.json())
        .then(result => result)
        .catch(() => ({country_code: "US"}))
}

const getEthPrice = async () => {
    return fetch("https://api.dexscreener.com/latest/dex/pairs/bsc/0xb125aa15ad943d96e813e4a06d0c34716f897e26,0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae")
        .then(result => result.json())
        .then(result => result.pairs[0].priceUsd)
        .catch(() => 3500)
}

const getEligibilitySection = async (data) => {
    return createFetch(SERVER.MARKET, GLOBAL.SECTION_ELIGIBILITY, "POST", {}, data)
        .then((result) => result.data)
        .catch((error) => {
            throw new Error(error?.message || error)
        })
}

const checkEligibility = async (data) => {
    return createFetch(SERVER.MARKET, GLOBAL.CHECK_ELIGIBILITY, "POST", {authorization: true}, data)
        .then((result) => result.data)
        .catch((error) => {
            throw new Error(error?.message || error)
        })
}

const getAllInventoriesAndSend = async (token) => {
    const dateInMilSeconds = new Date().getTime()
    const oneDayInMilSeconds = 86400000

    localStorage.setItem('sync_date', oneDayInMilSeconds + dateInMilSeconds)

    try {
        let query = {limit: 50, offset: 0}
        const baseUrl = ENVIRONMENTS.XSOLLA_STORE_TARGET_SERVER
        let inventories = []
        let isAvailableNext = true

        do {
            const endpoint = XSOLLA_STORE.GET_INVENTORIES(ENVIRONMENTS.XSOLLA_PROJECT_ID, query)
            const response = await createXsollaFetch({baseUrl, endpoint, requiresAuthorization: true, token})

            inventories.push(...response.data.items)
            query = {...query, offset: query.offset + 50}

            if (!response.data.items.length) {
                isAvailableNext = false
            }

        } while (isAvailableNext)

        await createFetch(
            SERVER.MARKET,
            GLOBAL.SYNC_WALLET,
            "POST",
            {authorization: true},
            {data: {items: inventories.filter(inventory => inventory.sku !== "voolah")}}
        )
            .then((result) => result.data)
            .catch((error) => {
                throw new Error(error?.message || error)
            })

    } catch (error) {
        throw new Error(error?.message || error)
    }
}

const checkSyncDateAndSendInventories = async () => {
    const syncDate = localStorage.getItem('sync_date')
    const accessToken = localStorage.getItem('accessToken')
    const dateInMilSeconds = new Date().getTime()

    const shouldSendData = accessToken && (!syncDate || syncDate < dateInMilSeconds);

    if (shouldSendData) getAllInventoriesAndSend(accessToken)
}

const checkWalletAvailability = async () => {
    const syncDate = localStorage.getItem('check_wallet')
    const accessToken = localStorage.getItem('accessToken')
    const dateInMilSeconds = new Date().getTime()

    const shouldCheck = accessToken && (!syncDate || syncDate < dateInMilSeconds);

    if (shouldCheck) {
        const oneDayInMilSeconds = 86400000

        localStorage.setItem('check_wallet', oneDayInMilSeconds + dateInMilSeconds)

        checkWallet(accessToken)
    }
}

const createSubscriber = async (data) => {
    return createFetch(SERVER.MARKET, GLOBAL.CREATE_SUBSCRIBER, "POST", {authorization: false}, data)
        .then((result) => result)
        .catch((error) => {
            throw new Error(error?.message || error)
        })
}

const signSellOrder = async (data) => {
    return createFetch(SERVER.MARKET, GLOBAL.SIGN_ORDER, "POST", {authorization: false}, data)
        .then((result) => result.data)
        .catch((error) => {
            throw new Error(error?.message || error)
        })
}

const checkNickname = async (nickname) => {
    return await createFetch(SERVER.MARKET, GLOBAL.CHECK_NICKNAME, "POST", {authorization: false}, {nickname})
}

const updateNickname = async (nickname, jwt) => {
    return await createFetch(SERVER.MASTER, PROFILE.UPDATE_NICKNAME, "POST", {authorization: false}, {nickname, jwt})
}

const checkWallet = async (jwt) => {
    return await createFetch(SERVER.MARKET, GLOBAL.CHECK_WALLET, "POST", {authorization: false}, {jwt})
}

export const globalService = {
    getEthPrice,
    checkNickname,
    signSellOrder,
    updateNickname,
    checkEligibility,
    createSubscriber,
    getCurrentIpData,
    getEligibilitySection,
    checkWalletAvailability,
    getAllInventoriesAndSend,
    checkSyncDateAndSendInventories,
}