import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {ERROR, GLOBAL_MODALS, STEPS_STATUS, voolahPackageDetails} from "utils";
import shopService from "store/VoolahShop/voolahShopService";
import {useGlobalModalsContext} from "layouts";
import useVoolahShop from "../useVoolahShop";
import {useWindowSize} from "hooks";

import {ButtonV2, Image, InputV2, Modal} from "components/atoms";
import {PaymentMethod} from "components/molecules";

import {png} from "assets/png";

const VoolahOrderModal = ({show, setCurrentModal, currentPackageIndex}) => {
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {t} = useTranslation()
    const {setXPayWidgetClose} = useVoolahShop()
    const {width} = useWindowSize()

    const [promoCode, setPromoCode] = useState("")
    const [isValidPromoCode, setIsValidPromoCode] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const cancelOrder = () => {
        setCurrentModal({status: STEPS_STATUS.CLOSE})
    }

    const openWidget = async () => {
        setIsLoading(true)
        setIsValidPromoCode(true)
        const itemSku = voolahPackageDetails[currentPackageIndex].value + "voolah"
        const size = width > 991 ? "large" : "small"
        const version = width > 600 ? "desktop" : "mobile"
        setXPayWidgetClose(false)

        if (promoCode) {
            let isPromoCodeValid = await shopService.validatePromoCode(promoCode)

            if (!isPromoCodeValid) {
                setIsLoading(false)
                setIsValidPromoCode(false)
                return
            }
        }

        const isValidOrder = await shopService.paymentVoolah({
            itemSku,
            promoCode,
            ui: {size, version},
            searchParams: `tab=voolah&voolah=${currentPackageIndex}`
        })

        if (isValidOrder) {
            window.XPayStationWidget.open()
            setCurrentModal({status: STEPS_STATUS.CLOSE})
        } else {
            setCurrentModal({status: STEPS_STATUS.CLOSE})
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.WRONG)

            resetStatesAndClose()
        }
        setIsLoading(false)
    }

    return (
        <Modal
            isOpen={show}
            title={t("limitedInventoryOrderModalTitle")}
            onClose={() => cancelOrder()}
        >
            <div className="shop-voolah_order-summery_content">
                <div className="shop-voolah_order-summery_content_info">
                    <div className="shop-voolah_order-summery_content_info_img">
                        <Image alt="cosmetic" src={voolahPackageDetails[currentPackageIndex].src.small}/>
                    </div>
                    <div className="shop-voolah_order-summery_content_info_details">
                        <div className="shop-voolah_order-summery_content_info_details_title">
                            <Image alt="voolah" src={png.VoolahCoin}/>
                            <h5>{voolahPackageDetails[currentPackageIndex].value} Voolah</h5>
                        </div>
                    </div>
                </div>
                <div className="shop-voolah_order-summery_content_payments-methods">
                    <div className="shop-voolah_order-summery_content_payments-methods_head">
                        <div className="shop-voolah_order-summery_content_payments-methods_head_quantity">
                            <span>{t("limitedInventoryOrderModalPaymentMethod")}</span>
                            <span> (1)</span>
                        </div>
                    </div>
                    <PaymentMethod
                        method="xsolla"
                        checked={true}
                        recommended={true}
                        disable={true}
                    />
                </div>
                <div className="shop-voolah_order-summery_content_promo-code">
                    <InputV2
                        value={promoCode}
                        label="Creator Code"
                        isError={!isValidPromoCode}
                        placeholder="Enter code here"
                        onChange={(e) => setPromoCode(e.target.value)}
                        errorMessage={!isValidPromoCode ? "Invalid Promo Code" : ""}
                    />
                </div>
                <div className="shop-voolah_order-summery_content_total">
                    <div>{t("total")}</div>
                    <div>${voolahPackageDetails[currentPackageIndex].price} USD</div>
                </div>
                <div className="shop-voolah_order-summery_content_buttons">
                    <ButtonV2 loading={isLoading} onClick={openWidget}>{t("payNow")}</ButtonV2>
                </div>
                <p className="shop-voolah_order-summery_content_terms">{t("voolahPaymentTerms")}</p>
            </div>
        </Modal>
    );
}

export default VoolahOrderModal