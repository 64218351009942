import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import React, {Suspense} from "react";

import {routes} from "./routes";

import GlobalModals from "components/organisms/GlobalModals";
import {LoadSpinnerAllPage} from "components/atoms";

const Routers = () => {
    return (
        <Suspense fallback={<LoadSpinnerAllPage show={true}/>}>
            <Router>
                <Routes>
                    {React.Children.toArray(
                        routes.map(({path, element}) =>
                            <Route path={path} element={element}/>
                        ))}
                </Routes>
                <GlobalModals/>
            </Router>
        </Suspense>
    )
}

export default Routers
