import React, {useState, memo, useEffect, useRef} from "react";

import {useWeb3ModalProvider} from "@web3modal/ethers5/react";
import {formatUnits} from "ethers/lib/utils";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {ethers} from "ethers";
import {
    wait,
    encryptData,
    errorHandler,
    scrollInToView,
    ERROR,
    SUCCESS,
    LOCK_SECTION,
    STEPS_STATUS,
    GLOBAL_MODALS,
    USER_LOCK_TIME,
    LIMIT_DEFAULT_DATA,
    STANDARD_LIMIT_OPTIONS,
    OWNED_INVENTORIES_REQUEST_DEFAULT_DATA,
} from "utils";
import {useCreateKonvertOrderMutation, useGetInventoryQuery} from "store/Locker/locker.api";
import {useConnectedWallet, useConnectWallet, useGMTEvents} from "hooks";
import {useGlobalModalsContext, useModalsContext} from "layouts";
import {globalService} from "store/Global/globalService";
import {lockerService} from "store/Locker/lockerService";

import LockerConvertSelectorBlocked from "./LockerConvertSelectorBlocked";
import {Image, Rarity, CheckBox2, Button} from "components/atoms";
import {PaginationArrowWithTools} from "components/molecules";

import {Icon} from "components/atoms";

const LockerConvertSelector = ({isSocketReceivedData}) => {
    const {
        data: eligibilityItems,
        success: eligibilitySuccess,
        loading: eligibilityLoading,
    } = useSelector(state => state.locker.konvert_eligibility)

    const {currentChain} = useSelector(state => state.web3)
    const {userData} = useSelector(state => state.authV2.signIn)

    const [areAllItemsSelected, setAreAllItemsSelected] = useState(false);
    const [checkedItemsSkus, setCheckedItemsSkus] = useState([])
    const [checkedItems, setCheckedItems] = useState([])

    const navigate = useNavigate()
    const {
        address,
        providers,
        isConnected,
        isConnectedAddressCorrect,
    } = useConnectedWallet()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {walletProvider} = useWeb3ModalProvider()
    const {openWalletModal} = useConnectWallet()
    const {setCurrentModal} = useModalsContext()
    const {NFTConversionStart} = useGMTEvents()

    const selectorBlocRef = useRef(null)

    const [limitValue, setLimitValue] = useState(LIMIT_DEFAULT_DATA)
    const [requestState, setRequestState] = useState(OWNED_INVENTORIES_REQUEST_DEFAULT_DATA)

    const {data, isLoading, isFetching, refetch} = useGetInventoryQuery({...requestState}, {
        refetchOnFocus: true,
        pollingInterval: 120000,
    })

    const [createOrder] = useCreateKonvertOrderMutation()

    const isEligibility = eligibilitySuccess ? eligibilityItems.find(item => item.eligibility === true) : false

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => selectAllChecking(), [checkedItemsSkus, data])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => followSocketReceivedData(), [isSocketReceivedData])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkIsVoolahAvailableAndRefetch(), [data]);

    const checkIsVoolahAvailableAndRefetch = () => {
        if (data && data.is_voolah_available) {
            setRequestState(prev => ({...prev, offset: 1}))
        }
    }

    const resetStates = () => {
        setAreAllItemsSelected(false)
        setCheckedItemsSkus([])
        setCheckedItems([])
    }

    const setErrorModal = (message) => {
        setModal(GLOBAL_MODALS.ERROR)
        setMessage(message)
        resetStatesAndClose()
    }

    const checkNextPageAvailability = async (pageDirection) => {
        if (pageDirection === "next") {
            const {offset, limit} = requestState
            const {items} = await lockerService.getUserInventory({offset: offset + limit, limit: 1})

            const isAvailableNext = items && items.length > 0

            if (!isAvailableNext) return false
        }

        return true
    }

    const selectAllChecking = () => {
        if (checkedItemsSkus.length) {
            const {web2_inventories} = data
            let areAllItemsChecked

            if (web2_inventories.length) {
                areAllItemsChecked = web2_inventories.every(inventory => checkedItemsSkus.includes(inventory.sku))
            }

            setAreAllItemsSelected(areAllItemsChecked)
        }
    }

    const handleSelectionChange = () => {
        const {owned_inventories} = data

        const currentInventorySkus = owned_inventories.map(inventory => inventory.sku)
        const currentWeb2Inventory = owned_inventories.filter(inventory => !inventory.is_nft)

        setAreAllItemsSelected(prevState => {
            if (!prevState === true) {
                currentWeb2Inventory.forEach(inventory => {
                    const isCheckedAlready = checkedItemsSkus.includes(inventory.sku)

                    if (!isCheckedAlready) {
                        setCheckedItemsSkus(prevState => [...prevState, inventory.sku])
                        setCheckedItems(prevState => [...prevState, {
                            image_url: inventory.image_url,
                            quantity: inventory.quantity,
                            item_id: inventory.item_id,
                            rarity: inventory.rarity,
                            name: inventory.name,
                            token_id: undefined,
                            sku: inventory.sku,
                        }])
                    }
                })
            } else {
                currentInventorySkus.forEach(sku => {
                    setCheckedItemsSkus(prevState => prevState.filter(item => sku !== item))
                    setCheckedItems(prevState => prevState.filter(item => item.sku !== sku))
                })
            }

            return !prevState;
        });
    }

    const handleCheckedItemsChanged = (item) => {
        if (!item.is_nft) {
            const isCheckedAlready = checkedItemsSkus.includes(item.sku)

            if (isCheckedAlready) {
                setCheckedItemsSkus(prevState => prevState.filter(sku => sku !== item.sku))
                setCheckedItems(prevState => prevState.filter(inventory => inventory.sku !== item.sku))
            } else {
                setCheckedItemsSkus(prevState => [...prevState, item.sku])
                setCheckedItems(prevState => [...prevState, {
                    image_url: item.image_url,
                    token_id: item.token_id,
                    quantity: item.quantity,
                    item_id: item.item_id,
                    rarity: item.rarity,
                    name: item.name,
                    sku: item.sku,
                }])
            }
        }
    }

    const checkLockAndSetDuration = async (duration) => {
        const isUserLock = await lockerService.userLockCheck({section: LOCK_SECTION.KONVERT})

        if (isUserLock) errorHandler(ERROR.KONVERT_COOLDOWN)

        await lockerService.userKonvertLock({duration: duration})
    }

    const checkUserSockets = async () => {
        const userSockets = await lockerService.getUsersSockets({user_xsolla_id: userData.id})

        if (userSockets.length > 1) {
            errorHandler(ERROR.FEW_SOCKETS)
        }
    }

    const checkUserWalletLock = async () => {
        const isUserWalletLock = await lockerService.userWalletLockCheck({section: LOCK_SECTION.KONVERT})

        if (isUserWalletLock) errorHandler(ERROR.WALLET_LOCKED)
    }

    const sendOrder = async (hash) => {
        const timestamp = new Date().getTime()

        const cryptoSecretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY_TWO;
        const cryptoIv = process.env.REACT_APP_CRYPTO_IV_TWO;

        const encryptedOrder = encryptData({
            hash,
            timestamp,
            inventories: checkedItems,
            chain_id: currentChain.chainId
        }, cryptoSecretKey, cryptoIv)

        await createOrder({order: encryptedOrder})
    }

    const convert = async () => {
        setModal(GLOBAL_MODALS.PROCESS)

        try {
            const {walletConnectProvider} = providers()
            const balance = await walletConnectProvider.getBalance(address)
            const ethBalance = Number(formatUnits(balance.toString()))

            await checkUserSockets()

            if (ethBalance < 0.002) errorHandler(ERROR.INSUFFICIENT_BALANCE)

            await checkUserWalletLock()

            if (checkedItems.length > 100) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.KONVERT_ITEMS_LIMIT)
                await wait(5000)
                setModal(GLOBAL_MODALS.CLOSE)

                return
            }

            await checkLockAndSetDuration(USER_LOCK_TIME.YEAR_1)

            if (!isConnectedAddressCorrect) {
                setErrorModal(ERROR.WALLET_CONNECTED_MATCH_INVALID)
            } else {
                if (isEligibility && !!checkedItemsSkus.length && isConnectedAddressCorrect) {
                    const ids = checkedItems.map(item => item.item_id)
                    const names = checkedItems.map(item => item.name)

                    NFTConversionStart(userData.id, ids, names)

                    const getInventoryIds = await lockerService.getInventoryTokenIdBySku({skus: checkedItemsSkus})

                    checkedItems.forEach((item2) => {
                        let skuWithoutWeb2 = item2.sku.replace('-web2', '')

                        getInventoryIds.some((item1) => {
                            if (skuWithoutWeb2.startsWith(item1.sku)) {
                                item2.token_id = item1.token_id;
                                return true;
                            }
                            return false;
                        });
                    });

                    const ethPrice = await globalService.getEthPrice() // USD
                    const oneDollarETH = (1 / ethPrice).toFixed(7) // $1 => ETH

                    const walletConnectProvider = new ethers.providers.Web3Provider(walletProvider)
                    const walletSigner = walletConnectProvider.getSigner()

                    const tx = await walletSigner.sendTransaction({
                        to: process.env.REACT_APP_FEE_RECEIVER,
                        value: ethers.utils.parseEther(oneDollarETH)
                    })

                    await sendOrder(tx.hash)

                    tx.wait().then(async (result) => {
                        if (result.status) {
                            setModal(GLOBAL_MODALS.SUCCESS)
                            setMessage(SUCCESS.KONVERT_ORDER)
                            await wait(5000)
                            setModal(GLOBAL_MODALS.CLOSE)
                            navigate(("/locker?tab=owned"))
                        } else {
                            setModal(GLOBAL_MODALS.ERROR)
                            setMessage(ERROR.TRANSACTION_FAILED)
                            await wait(3000)
                            setModal(GLOBAL_MODALS.CLOSE)
                        }
                    })
                        .catch(async (error) => {
                            setModal(GLOBAL_MODALS.ERROR)
                            setMessage(error.message)
                            await wait(3000)
                            setModal(GLOBAL_MODALS.CLOSE)
                        })
                    resetStates()
                }
            }
        } catch (error) {
            switch (error.code ?? error.message) {
                case ERROR.KONVERT_COOLDOWN:
                    setModal(GLOBAL_MODALS.CLOSE)
                    setCurrentModal({status: STEPS_STATUS.KONVERT_COOLDOWN})
                    break
                case ERROR.WALLET_LOCKED:
                    setModal(GLOBAL_MODALS.CLOSE)
                    setCurrentModal({status: STEPS_STATUS.KONVERT_COOLDOWN_V2})
                    break
                case "ACTION_REJECTED":
                    await lockerService.userKonvertLock({duration: USER_LOCK_TIME.MINUTES_60})
                    setModal(GLOBAL_MODALS.CLOSE)
                    break
                default:
                    setErrorModal(error?.message ?? ERROR.WRONG)
            }
        }
    }

    const updateRequestState = (newState) => setRequestState({...requestState, ...newState});

    const limitValueHandler = (event) => {
        const {value} = event.target.value;

        updateRequestState({offset: 0, limit: value.value})
        scrollInToView(selectorBlocRef)
        setLimitValue(value)
    }

    const pageChangeHandler = async (arrow) => {
        const {offset, limit} = requestState;
        const newSkip = arrow === "next" ? offset + limit : offset - limit;

        if (!await checkNextPageAvailability(arrow)) return

        updateRequestState({offset: newSkip})
        scrollInToView(selectorBlocRef)
    }

    const followSocketReceivedData = () => {
        if (isSocketReceivedData) {
            const data = JSON.parse(isSocketReceivedData)

            if (data.refetch) {
                refetch()
                setCheckedItemsSkus([])
                setCheckedItems([])
            }
        }
    }

    const allInventoriesQuantity = data?.owned_inventories?.length

    return (
        <div ref={selectorBlocRef} className="locker_cosmetics_content_general_konvert_selector">
            {isLoading || eligibilityLoading
                ? <div className="locker_cosmetics_content_general_konvert_selector_loading-container">
                    {Array(12).fill("").map((_, index) =>
                        <ConvertibleItem
                            key={index}
                            isLoading={true}
                        />)}
                </div>
                : <React.Fragment>
                    <h2>Konvert</h2>
                    <p>Select the items you want to Konvert.</p>
                    <div className="locker_cosmetics_content_general_konvert_selector_items">
                        <div className="locker_cosmetics_content_general_konvert_selector_items_head">
                            <CheckBox2
                                checked={areAllItemsSelected}
                                customTextStyle="custom-text"
                                onChange={() => handleSelectionChange()}
                            >
                                <div>Select All</div>
                            </CheckBox2>
                            <div>({checkedItemsSkus.length}) Selected</div>
                        </div>
                        {!!allInventoriesQuantity &&
                            <React.Fragment>
                                <div className="locker_cosmetics_content_general_konvert_selector_items_container">
                                    {data?.owned_inventories.map(item =>
                                        <ConvertibleItem
                                            item={item}
                                            key={item.item_id}
                                            isLoading={isLoading || isFetching}
                                            isChecked={checkedItemsSkus.includes(item.sku)}
                                            checkCallBack={(item) => handleCheckedItemsChanged(item)}
                                        />
                                    )}
                                </div>
                                <div className="locker_cosmetics_content_general_konvert_selector_items_footer">
                                    {!!allInventoriesQuantity && <PaginationArrowWithTools
                                        limitValue={limitValue}
                                        offset={requestState.offset}
                                        callBackLimit={limitValueHandler}
                                        callBackArrow={pageChangeHandler}
                                        dataCount={allInventoriesQuantity}
                                        limitData={STANDARD_LIMIT_OPTIONS}
                                    />}
                                </div>
                                <div className="locker_cosmetics_content_general_konvert_selector_items_btn">
                                    <Button
                                        disabled={isEligibility && !checkedItemsSkus.length}
                                        onClick={() => isConnected ? convert() : openWalletModal()}
                                    >
                                        {isConnected ? "Konvert" : "Connect Wallet"}
                                    </Button>
                                </div>
                            </React.Fragment>}
                    </div>

                    {!isEligibility && !eligibilityLoading &&
                        <LockerConvertSelectorBlocked text="To use the Konverter you must complete the tasks above."/>}

                    {!allInventoriesQuantity && !isLoading &&
                        <LockerConvertSelectorBlocked text="To use the Konverter you must buy the Web2 items"/>
                    }
                </React.Fragment>}
        </div>
    );
};

export default memo(LockerConvertSelector);

const ConvertibleItem = ({item, isChecked, checkCallBack, isLoading}) => {
    const baseClass = "locker_cosmetics_content_general_konvert_selector_items_container_item"
    const imageBlockStyle = classNames(`${item?.rarity}-small`)
    const itemStyle = classNames(baseClass, {skeleton: isLoading})

    const handleSelectionChange = () => checkCallBack(item);

    return (
        <div className={itemStyle} onClick={handleSelectionChange}>
            {!isLoading &&
                <React.Fragment>
                    <div className={`${baseClass}_info`}>
                        <div className={imageBlockStyle}><Image src={item.image_url} alt="cosmetic"/></div>
                        <div>
                            <p className="title">{item.name}</p>
                            <span className="group">{item.groups[0]?.name}</span>
                            <Rarity rarity={item.rarity} size="md" text={item.rarity}/>
                        </div>
                    </div>
                    <div className={`${baseClass}_checkbox`}>
                        {item.is_nft && <div className={`${baseClass}_blocked`}>
                            <Icon name="unTradeable"/>
                        </div>}
                        <div className={`${baseClass}_checkbox_quantity`}>
                            <span>X{item.quantity}</span>
                        </div>
                        <CheckBox2
                            checked={isChecked}
                            onChange={() => {
                            }}
                        />
                    </div>
                </React.Fragment>}
        </div>
    );
};