import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {shopTabOptions} from "utils"
import {ModalsProvider} from "layouts";

import {TabContent, TabDynamicBlock} from "components/molecules";
import {LoadSpinnerAllPage} from "components/atoms";
import LimitedTimeShop from "./LimitedTimeShop";
import DivineShop from "./DivineShop";
import VoolahShop from "./VoolahShop";

import "./style.scss"

const ShopGlobal = ({widgetIsLoad}) => {
    const {cosmeticPayment} = useSelector(state => state.limitedShop)
    const {signIn} = useSelector(state => state.authV2)
    const [activeTab, setActiveTab] = useState("limited");
    const [searchParams, setSearchParams] = useSearchParams()
    const searchParam = searchParams.get("tab")

    useEffect(() => {
        if (searchParam) {
            setActiveTab(searchParams.get("tab"))
        } else {
            setSearchParams({tab: "limited"})
        }
        // eslint-disable-next-line
    }, [])

    const setParams = (tab) => {
        setSearchParams({tab: tab})
    }

    return (
        <React.Fragment>
            <ModalsProvider>
                <section className="shop_tabs">
                    <TabDynamicBlock
                        isSetUrlParams={true}
                        setParams={setParams}
                        options={shopTabOptions}
                        setActiveTab={setActiveTab}
                        activeTab={searchParam ? searchParam : activeTab}
                    />
                    <TabContent id="limited" activeTab={searchParam ? searchParam : activeTab}>
                        <LimitedTimeShop/>
                    </TabContent>
                    <TabContent id="divine" activeTab={searchParam ? searchParam : activeTab}>
                        <DivineShop/>
                    </TabContent>
                    <TabContent id="voolah" activeTab={searchParam ? searchParam : activeTab}>
                        <VoolahShop widgetIsLoad={widgetIsLoad}/>
                    </TabContent>
                </section>
                <LoadSpinnerAllPage show={(signIn.loading || cosmeticPayment.loading)}/>
            </ModalsProvider>
        </React.Fragment>
    )
}

export default ShopGlobal