import {CHAIN_TO_ADDRESSES_MAP} from "./addresses";
import {CHAIN_ID} from "../utils";

export const CHAIN_ENVIRONMENT = {
    MAINNET: 'mainNet',
    TESTNET: 'testNet'
}

export const CHAIN_HEX = {
    ETH_MAINNET: '0x1',
    ETH_SEPOLIA: '0xaa36a7',
    BASE_MAINNET: '0x2105',
    BASE_SEPOLIA: '0x14a34'
}

export const CHAIN_NAME = {
    ETH_MAINNET: 'Ethereum Mainnet',
    ETH_SEPOLIA: 'Ethereum Sepolia Testnet',
    BASE_MAINNET: 'Base',
    BASE_SEPOLIA: 'Base Sepolia Testnet'
}

export const CHAIN_NATIVE_CURRENCY_SYMBOL = {
    ETH_MAINNET: 'ETH',
    ETH_SEPOLIA: 'ETH',
    BASE_MAINNET: 'ETH',
    BASE_SEPOLIA: 'ETH'
}

export const PAYMENT_DECIMALS = {
    ETH_MAINNET: 10,
    ETH_SEPOLIA: 2,
    BASE_MAINNET: 10,
    BASE_SEPOLIA: 18
}

export const CHAIN_RPC_ENDPOINT = {
    ETH_MAINNET: "https://eth-mainnet.g.alchemy.com/v2/Au6czW17VYwPv3FXb2HoBOrnjYtWCQW_",
    ETH_SEPOLIA: "https://eth-sepolia.g.alchemy.com/v2/T9M4DV-LUN1MmIcUskTtorKm999R8z4R",
    BASE_MAINNET: "https://base-mainnet.g.alchemy.com/v2/x_eVZhLmGbE3TMaikFifO2a9gbXYO3OY",
    BASE_SEPOLIA: "https://base-sepolia.g.alchemy.com/v2/XoOCLHkcIJOnOtlgqxA6ZrjTd9nb8UF0"
}

export const CHAIN_RPC_API_KEY = {
    ETH_MAINNET: "Au6czW17VYwPv3FXb2HoBOrnjYtWCQW_",
    ETH_SEPOLIA: "T9M4DV-LUN1MmIcUskTtorKm999R8z4R",
    BASE_MAINNET: "x_eVZhLmGbE3TMaikFifO2a9gbXYO3OY",
    BASE_SEPOLIA: "T9M4DV-LUN1MmIcUskTtorKm999R8z4R"
}

export const CHAIN_BLOCK_EXPLORER = {
    ETH_MAINNET: 'EtherScan',
    ETH_SEPOLIA: 'EtherScan',
    BASE_MAINNET: 'BaseScan',
    BASE_SEPOLIA: 'BaseScan'
}

export const CHAIN_BLOCK_EXPLORER_URL = {
    ETH_MAINNET: 'https://etherscan.io/',
    ETH_SEPOLIA: 'https://sepolia.etherscan.io/',
    BASE_MAINNET: 'https://basescan.org/',
    BASE_SEPOLIA: 'https://sepolia.basescan.org/'
}


export const SUPPORTED_CHAINS_MAINNET = [CHAIN_ID.ETH_MAINNET, CHAIN_ID.BASE_MAINNET]

export const SUPPORTED_CHAINS_TESTNET = [CHAIN_ID.ETH_SEPOLIA, CHAIN_ID.BASE_SEPOLIA]

export const CHAIN_MAINNET_KEY_NAME = {
    [CHAIN_ID.ETH_MAINNET]: "ETH_MAINNET",
    [CHAIN_ID.BASE_MAINNET]: "BASE_MAINNET"
}

export const CHAIN_TESTNET_KEY_NAME = {
    [CHAIN_ID.ETH_SEPOLIA]: "ETH_SEPOLIA",
    [CHAIN_ID.BASE_SEPOLIA]: "BASE_SEPOLIA"
}

export const ETH_MAINNET_CHAIN_DATA = {
    chainId: CHAIN_ID.ETH_MAINNET,
    chainHex: CHAIN_HEX.ETH_MAINNET,
    chainName: CHAIN_NAME.ETH_MAINNET,
    rpcURL: CHAIN_RPC_ENDPOINT.ETH_MAINNET,
    rpcApiKey: CHAIN_RPC_API_KEY.ETH_MAINNET,
    paymentDecimals: PAYMENT_DECIMALS.ETH_MAINNET,
    blockExplorer: CHAIN_BLOCK_EXPLORER.ETH_MAINNET,
    symbol: CHAIN_NATIVE_CURRENCY_SYMBOL.ETH_MAINNET,
    blockExplorerUrl: CHAIN_BLOCK_EXPLORER_URL.ETH_MAINNET,
    addresses: CHAIN_TO_ADDRESSES_MAP[CHAIN_ID.ETH_MAINNET],
}

export const ETH_SEPOLIA_CHAIN_DATA = {
    chainId: CHAIN_ID.ETH_SEPOLIA,
    chainHex: CHAIN_HEX.ETH_SEPOLIA,
    chainName: CHAIN_NAME.ETH_SEPOLIA,
    rpcURL: CHAIN_RPC_ENDPOINT.ETH_SEPOLIA,
    rpcApiKey: CHAIN_RPC_API_KEY.ETH_SEPOLIA,
    paymentDecimals: PAYMENT_DECIMALS.ETH_SEPOLIA,
    blockExplorer: CHAIN_BLOCK_EXPLORER.ETH_SEPOLIA,
    symbol: CHAIN_NATIVE_CURRENCY_SYMBOL.ETH_SEPOLIA,
    addresses: CHAIN_TO_ADDRESSES_MAP[CHAIN_ID.ETH_SEPOLIA],
}

export const BASE_MAINNET_CHAIN_DATA = {
    chainId: CHAIN_ID.BASE_MAINNET,
    chainHex: CHAIN_HEX.BASE_MAINNET,
    chainName: CHAIN_NAME.BASE_MAINNET,
    rpcURL: CHAIN_RPC_ENDPOINT.BASE_MAINNET,
    rpcApiKey: CHAIN_RPC_API_KEY.BASE_MAINNET,
    paymentDecimals: PAYMENT_DECIMALS.BASE_MAINNET,
    blockExplorer: CHAIN_BLOCK_EXPLORER.BASE_MAINNET,
    symbol: CHAIN_NATIVE_CURRENCY_SYMBOL.BASE_MAINNET,
    blockExplorerUrl: CHAIN_BLOCK_EXPLORER_URL.BASE_MAINNET,
    addresses: CHAIN_TO_ADDRESSES_MAP[CHAIN_ID.BASE_MAINNET],
}

export const BASE_SEPOLIA_CHAIN_DATA = {
    chainId: CHAIN_ID.BASE_SEPOLIA,
    chainHex: CHAIN_HEX.BASE_SEPOLIA,
    chainName: CHAIN_NAME.BASE_SEPOLIA,
    rpcURL: CHAIN_RPC_ENDPOINT.BASE_SEPOLIA,
    rpcApiKey: CHAIN_RPC_API_KEY.BASE_SEPOLIA,
    paymentDecimals: PAYMENT_DECIMALS.BASE_SEPOLIA,
    blockExplorer: CHAIN_BLOCK_EXPLORER.BASE_SEPOLIA,
    symbol: CHAIN_NATIVE_CURRENCY_SYMBOL.BASE_SEPOLIA,
    blockExplorerUrl: CHAIN_BLOCK_EXPLORER_URL.BASE_SEPOLIA,
    addresses: CHAIN_TO_ADDRESSES_MAP[CHAIN_ID.BASE_SEPOLIA],
}

// CURRENT_SUPPORTED_CHAINS_ID[CURRENT_CHAINS_ENVIRONMENT] => [1,8453]
export const CURRENT_SUPPORTED_CHAINS_ID = {
    [CHAIN_ENVIRONMENT.MAINNET]: SUPPORTED_CHAINS_MAINNET,
    [CHAIN_ENVIRONMENT.TESTNET]: SUPPORTED_CHAINS_TESTNET
}

// CURRENT_SUPPORTED_CHAINS_NAME[CURRENT_CHAINS_ENVIRONMENT] => { '1': 'ETH_MAINNET', '8453': 'BASE_MAINNET' }
export const CURRENT_SUPPORTED_CHAINS_NAME = {
    [CHAIN_ENVIRONMENT.MAINNET]: CHAIN_MAINNET_KEY_NAME,
    [CHAIN_ENVIRONMENT.TESTNET]: CHAIN_TESTNET_KEY_NAME
}

// CHAIN_TO_PARAMS_MAP[{chainId}]
export const CHAIN_TO_PARAMS_MAP = {
    [CHAIN_ID.ETH_MAINNET]: ETH_MAINNET_CHAIN_DATA,
    [CHAIN_ID.ETH_SEPOLIA]: ETH_SEPOLIA_CHAIN_DATA,
    [CHAIN_ID.BASE_MAINNET]: BASE_MAINNET_CHAIN_DATA,
    [CHAIN_ID.BASE_SEPOLIA]: BASE_SEPOLIA_CHAIN_DATA
}
