import {ENV_CONFIG} from "config/config";

const paymentVoolah = async (data) => {
    const {itemSku, ui, searchParams, promoCode} = data;

    const accessToken = localStorage.getItem("accessToken");
    const baseUrl = ENV_CONFIG.XSOLLA.STORE_BASE_URL
    const projectId = ENV_CONFIG.XSOLLA.PROJECT_ID

    const requestOptions = {
        locale: "en",
        ...(promoCode && {promo_code: promoCode}),
        settings: {
            return_url: `${window.location.origin}/shop?${searchParams}`,
            redirect_policy: {
                redirect_condition: "none",
                status_for_manual_redirection: "none"
            },
            disable_saved_methods: false,
            ui: {
                theme: "dark",
                size: ui.size,
                version: ui.version,
                mobile: {header: {close_button: true}}
            }
        }
    };

    const initializeWidget = (ui, order) => {

        const getLightboxOptions = (token, size, version) => {
            const LIGHTBOX_CONFIG = {
                spinner: "round",
                zIndex: 100000
            };

            if (version === "desktop") {
                return {
                    access_token: token,
                    lightbox: {
                        width: size === "large" ? 744 : 630,
                        height: size === "large" ? 680 : 610,
                        ...LIGHTBOX_CONFIG
                    }
                };
            }
            return {
                access_token: token,
                lightbox: LIGHTBOX_CONFIG,
                childWindow: {
                    target: "_self"
                }
            };
        };

        const lightboxOptions = getLightboxOptions(order.token, ui.size, ui.version);
        window.XPayStationWidget.init(lightboxOptions);
    };

    try {
        const orderResponse = await fetch(`${baseUrl}/v2/project/${projectId}/payment/item/${itemSku}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(requestOptions)
        });

        const order = await orderResponse.json();

        if (orderResponse.status === 200) {
            initializeWidget(ui, order);
            return true;
        }
        return false;
    } catch {
        return false;
    }
};

const validatePromoCode = async (promoCode) => {
    const baseUrl = ENV_CONFIG.XSOLLA.STORE_BASE_URL
    const projectId = ENV_CONFIG.XSOLLA.PROJECT_ID
    const accessToken = localStorage.getItem("accessToken")

    try {
        const response = await fetch(`${baseUrl}/v2/project/${projectId}/promotion/code/${promoCode}/verify`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        })

        return response.status === 200;
    } catch {
        return false;
    }
}

const shopService = {
    paymentVoolah,
    validatePromoCode
}

export default shopService