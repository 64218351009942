import {CHAIN_ID} from "../utils";

const DEFAULT_ADDRESSES = {}

const ETH_MAINNET_ADDRESS = {
    ...DEFAULT_ADDRESSES,
    MARKETPLACE_ADDRESS: '0x9feca5714f6Bd3dE25C85de72d3953f6E13dC6fa',
    FACTORY_ADDRESS: '0xBdd0D42F51f5865391CfD988DF1d536f97dC7dc6',
    COLLECTION_ADDRESS: '0xc149B933DF9C68b1e4cb0656958C66e80bF3858d',
    PAYMENT_ADDRESS: '0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9',
    POOL_ADDRESS: '0x07840B4825b74cC6ce264bF2743DEe647194f49b'
}

const ETH_SEPOLIA_ADDRESS = {
    ...DEFAULT_ADDRESSES,
    MARKETPLACE_ADDRESS: '0xBdD83b33BdB1198Daf4651D9aE5e344A6045ff46',
    FACTORY_ADDRESS: '0x6dA563401a24c4539A4e77173Ffd83EC50527ce3',
    COLLECTION_ADDRESS: '0x06b8e5C6B5c5aFe1B2C2BBd05c7df449Cf8636ea',
    PAYMENT_ADDRESS: '0x77b38AF5404F0A921Ba38d9D99170d2E634B06D8',
    POOL_ADDRESS: '0x07840B4825b74cC6ce264bF2743DEe647194f49b'
}

const BASE_MAINNET_ADDRESS = {
    ...DEFAULT_ADDRESSES,
    MARKETPLACE_ADDRESS: '0xbd4AB595f66d2F13983160964B70Bf9b0D4c0Cc3',
    FACTORY_ADDRESS: '0x478ECd0e373Bf125e7b375D7352dBe317789fbE9',
    COLLECTION_ADDRESS: '0xB52e48048b02D5248D2Fb39774a636880EC7bea6',
    PAYMENT_ADDRESS: '0x8f019931375454Fe4EE353427eB94E2E0C9e0a8C',
    POOL_ADDRESS: '0xD9CAa6dBe6791fcb7fC9FB59d1A6b3DD8C1C2339'
}

const BASE_SEPOLIA_ADDRESS = {
    ...DEFAULT_ADDRESSES,
    MARKETPLACE_ADDRESS: '0x18aE54889B60BF9aF4dce8f92AC0fe1050c2C15A',
    FACTORY_ADDRESS: '0xcC5f471293957c77D6e35F7e8F630e5fAF3257C4',
    COLLECTION_ADDRESS: '0x478ECd0e373Bf125e7b375D7352dBe317789fbE9',
    PAYMENT_ADDRESS: '0x8c34ce443b0684455a689100CC0839Db1bdb5e6B',
    POOL_ADDRESS: '0xD9CAa6dBe6791fcb7fC9FB59d1A6b3DD8C1C2339'
}

// CHAIN_TO_ADDRESSES_MAP[chainId]
export const CHAIN_TO_ADDRESSES_MAP = {
    [CHAIN_ID.ETH_MAINNET]: ETH_MAINNET_ADDRESS,
    [CHAIN_ID.ETH_SEPOLIA]: ETH_SEPOLIA_ADDRESS,
    [CHAIN_ID.BASE_MAINNET]: BASE_MAINNET_ADDRESS,
    [CHAIN_ID.BASE_SEPOLIA]: BASE_SEPOLIA_ADDRESS
}

