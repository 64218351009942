import React, {memo} from "react";
import {useTranslation} from "react-i18next";

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {useAuthorization} from "hooks";

import {GLOBAL_MODALS} from "utils";

import OptionsBlock from "./OptionsBlock";
import {Button} from "components/atoms";
import SwitchBlock from "./SwitchBlock";
import InfoBlock from "./InfoBlock";

import "./../style.scss"

const UserLoginDisplay = ({signOut, SetMobileMenu}) => {
    const {accessToken} = useAuthorization()
    const {setModal} = useGlobalModalsContext()
    const {t} = useTranslation()

    return (
        <React.Fragment>
            {accessToken
                ? <div className="navigation_auth_user-details">
                    <div className="navigation_auth_user-details_panel">
                        <SwitchBlock/>
                        <InfoBlock/>
                        <OptionsBlock signOut={signOut} setMobileMenu={SetMobileMenu}/>
                    </div>
                </div>
                : <div className="navigation_auth_signIn-btn">
                    <Button
                        size="sm"
                        view="white"
                        onClick={() => setModal(GLOBAL_MODALS.SIGN_IN)}
                    >
                        {t("signInModalFormSignInButton")}
                    </Button>
                </div>}
        </React.Fragment>
    )
}

export default memo(UserLoginDisplay)