import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useWindowSize} from "hooks";

import DeviceCard from "./DeviceCard";

export const DeviceCardBlock = ({videoHeight}) => {
    const [position, setPosition] = useState({bottom: 100})
    const {height} = useWindowSize()
    const {t} = useTranslation()

    useEffect(() => {
        const handleScroll = () => {
            setPosition({ bottom: 100 });
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (height && videoHeight) {
            setPosition(height > videoHeight ? {bottom: 100} : {top: height - 250})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height,videoHeight]);

    return (<div style={position} className="home_main_download">
        <div className="home_main_download_for-free">
            <span>{t("homePageTitle")}:</span>
            <div className="home_main_download_for-free_links">
                <DeviceCard
                    icon="modernize"
                    device={t("availablePlatformsWindows")}
                    link="https://www.modernizegames.com/ModernizeInstaller.exe"
                />
                <DeviceCard
                    icon="epicGames"
                    isTarget={true}
                    device={t("availablePlatformsWindows")}
                    link="https://store.epicgames.com/en-US/p/kompete-73c94a"
                />
                <DeviceCard
                    icon="appStore"
                    isTarget={true}
                    device={t("comingPlatformsIOS")}
                    link="https://apps.apple.com/us/app/kompete-early-access/id6446167138"
                />
                <DeviceCard
                    icon="googlePlay"
                    isTarget={true}
                    device={t("availablePlatformsAndroid")}
                    link="https://play.google.com/store/apps/details?id=com.Kompete"
                />
            </div>
        </div>
        <div className="home_main_download_coming-soon">
            <span>{t("homePageComingSoon")}:</span>
            <div className="home_main_download_coming-soon_links">
                <DeviceCard
                    icon="playstation"
                    device={t("comingPlatformsPlayStation")}
                />
                <DeviceCard
                    icon="xbox"
                    device={t("comingPlatformsXbox")}
                />
            </div>
        </div>
    </div>)
}

export default DeviceCardBlock