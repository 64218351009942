import {createApi} from "@reduxjs/toolkit/query/react";

import createFetchBaseSeverQuery from "config/createFetchBaseServerQuery";
import createFetchBaseQuery from "config/createFetchBaseQuery";
import {ENVIRONMENTS, inventoryConvertor} from "utils";
import {LOCKER, XSOLLA_STORE} from "../endpoint";

export const lockerApi = createApi({
    reducerPath: 'locker/api',
    baseQuery: createFetchBaseQuery({baseUrl: ENVIRONMENTS.XSOLLA_STORE_TARGET_SERVER, isAuthorization: true}),
    endpoints: (builder) => ({
        getInventory: builder.query({
            query: (data) => ({
                url: XSOLLA_STORE.GET_INVENTORIES(ENVIRONMENTS.XSOLLA_PROJECT_ID, data),
                method: "GET",
            }),
            keepUnusedDataFor: 5,
            transformResponse: (response) => {
                const {items} = response

                items.forEach(item => {
                    inventoryConvertor(item)
                })

                const web2Inventories = items.filter(item => !item.is_nft)

                return {
                    owned_inventories: items,
                    web2_inventories_quantity: web2Inventories.length,
                    web2_inventories: web2Inventories,
                    is_voolah_available: web2Inventories.some(inventory => inventory.sku === "voolah")
                }
            },
            transformErrorResponse: (response) => {
                return response
            },
        }),
        getCurrentInventory: builder.query({
            query: (data) => ({
                url: XSOLLA_STORE.GET_CURRENT_INVENTORY(ENVIRONMENTS.XSOLLA_PROJECT_ID, data),
                method: "GET",
            }),
            keepUnusedDataFor: 1,
            transformResponse: (response) => {
                inventoryConvertor(response)

                return response
            },
        }),
    }),

})

export const lockerMarketApi = createApi({
    reducerPath: 'locker/market/api',
    baseQuery: createFetchBaseSeverQuery({baseUrl: ENVIRONMENTS.MARKET_URL, isAuthorization: true}),
    endpoints: (builder) => ({
        createKonvertOrder: builder.mutation({
            query: (data) => ({
                url: LOCKER.CREATE_KOVERTA_ORDER,
                method: "POST",
                body: {data},
            })
        })
    })
})

export const {useGetInventoryQuery, useGetCurrentInventoryQuery} = lockerApi

export const {useCreateKonvertOrderMutation} = lockerMarketApi