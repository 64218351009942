import {getERC20Contract} from "@modernize-games/nft-sdk";
import {formatUnits} from "ethers/lib/utils";
import {useDispatch} from "react-redux";
import {ethers} from "ethers";

import {updateKompeteBalance, updateEthBalance} from "store/AuthV2/AuthV2Slice";
import {CHAIN_TO_PARAMS_MAP} from "web3/chains";

export const useBalance = () => {
    const dispatch = useDispatch()

    const getKMTBalance = async (chainId, address) => {
        try {
            const chainParamsMap = CHAIN_TO_PARAMS_MAP[chainId]
            const {addresses, rpcURL} = chainParamsMap

            const provider = new ethers.providers.JsonRpcProvider(rpcURL)
            const ERC20 = getERC20Contract(addresses.PAYMENT_ADDRESS, provider)
            const balance = await ERC20.balanceOf(address)
            const decimals = await ERC20.decimals()

            return Number(formatUnits(balance.toString(), decimals))
        } catch(error) {
            return 0
        }
    }

    const getBalance = async (chainId, address) => {
        try {
            const chainParamsMap = CHAIN_TO_PARAMS_MAP[chainId]
            const {rpcURL} = chainParamsMap
            const provider = new ethers.providers.JsonRpcProvider(rpcURL)

            const balance = await provider.getBalance(address)

            return Number(formatUnits(balance.toString()))
        } catch {
            return 0
        }
    }

    const getKMTBalanceAndSave = async (chainId, address) => {
        try {
            const balance = await getKMTBalance(chainId, address)
            dispatch(updateKompeteBalance(balance))
        } catch {
            dispatch(updateKompeteBalance(0))
        }
    }

    const getBalanceAndSave = async (chainId, address) => {
        try {
            const balance = await getBalance(chainId, address)
            dispatch(updateEthBalance(balance))
        } catch {
            dispatch(updateEthBalance(0))
        }
    }

    return {
        getBalance,
        getKMTBalance,
        getBalanceAndSave,
        getKMTBalanceAndSave,
    }
}